<template>
    <div class="header-search-modal" id="header-search-modal">
        <button class="card-close sidebar-close">
            <i class="fas fa-times"></i>
        </button>
        <div class="header-search-wrap">
            <div class="card-header">
                <div class="form-group">
                    <label for="format"></label>
                    <select name="format" id="format" v-model="format">
                        <option value="moxfield">Moxfield </option>
                        <option value="arena">Arena </option>
                        <option value="plain">Lista </option>
                    </select>
                </div>
                <div>
                    <textarea
                        class="form-control"
                        rows="20"
                        :placeholder="$t('Másold be a decklistet ide')"
                        style="min-height: 200px"
                        v-model="filterP.s"
                    />
                </div>
                <!--<div class="input-group">
                        <input
                            type="search"
                            class="form-control"
                            name="prod-search"
                            id="prod-search"
                            v-model="filterP.s"
                            v-on:keyup="startSearch"
                            :placeholder="$t('Keress valamit....')"
                        />
                        <button type="submit" class="axil-btn btn-bg-primary">
                            <i class="far fa-search"></i>
                        </button>
                    </div>-->
            </div>
            <div class="card-body">
                <button
                    type="button"
                    class="axil-btn btn-bg-primary"
                    v-on:click="searchDecklist"
                >
                    {{ $t("Keresés") }}
                </button>
                <hr />
                <div
                    class="psearch-results"
                    v-if="!items.data || items.data.length < 1"
                >
                    <b>{{ $t("0 db találat") }}</b>
                </div>
                <div class="psearch-results">
                    <div
                        class="axil-product-list"
                        v-for="(item, index) in items.data"
                        v-bind:key="'item-s' + index"
                    >
                        <div class="thumbnail">
                            <a href="single-product.html">
                                <img
                                    v-if="
                                        !item.image_uris ||
                                            !item.image_uris.normal
                                    "
                                    src="/assets/images/product/electric/product-09.png"
                                    alt="Product Images"
                                />
                                <img
                                    v-else
                                    :src="item.image_uris.normal"
                                    :alt="item.name"
                                    style="max-height: 90px"
                                />
                            </a>
                        </div>
                        <div class="product-content">
                            <h6 class="product-title">
                                <a href="javascript:void(0);"
                                    >{{ item.name }}
                                    <hr />
                                    {{ item.quantity }} db készleten
                                </a>
                            </h6>
                            <div class="product-price-variant">
                                <span class="price current-price">{{
                                    item.price
                                }}</span>
                            </div>
                            <div class="product-cart">
                                <b-input-group class="mt-3">
                                    <b-form-input
                                        type="number"
                                        style="border: 1px solid #ccc; line-height: 40px"
                                        value="1"
                                        v-model="quantity[item.id]"
                                        :max="item.quantity"
                                        min="1"
                                    ></b-form-input>
                                    <template #append>
                                        <a
                                            href="#"
                                            class="cart-btn"
                                            v-on:click.prevent="
                                                addToCart(item.id)
                                            "
                                            v-if="
                                                item.sellable === 1 &&
                                                    item.price_standard > 0
                                            "
                                            ><i class="fal fa-shopping-cart"></i
                                        ></a>
                                    </template>
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
    name: "HeaderSearchModal",
    methods: {
        searchDecklist() {
            if (this.filterP.s) {
                ApiService.post(`/${this.indexUrl}/search-decklist`, {
                    decklist: this.filterP.s,
                    format: this.format
                }).then(resp => {
                    this.items = resp.data;
                    for (const it in this.items.data) {
                        this.quantity[this.items.data[it].id] = 1;
                    }
                });
            }
        },
        goToAll(s) {
            // eslint-disable-next-line no-undef
            jQuery(".card-close").click();
            this.$router.push({ name: "Index", query: { s: s } });
        },
        startSearch: function(page = null) {
            page = 1;
            const self = this;
            clearTimeout(this.searchTimeout);
            this.searchTimeout = setTimeout(function() {
                self.isBusy = true;
                let helper = JSON.parse(JSON.stringify(self.filterP));
                if (page) {
                    if (self.page !== page) {
                        self.page = page;
                    }
                    helper.page = page;
                } else {
                    helper.page = 1;
                }

                ApiService.query(
                    `${self.indexUrl}/list/?${self.encodeQueryData(helper)}`
                ).then(resp => {
                    self.items = resp.data;
                    for (const it in self.items) {
                        self.quantity[self.items[it].id] = 1;
                    }
                    self.numberOfPage = resp.data.meta.last_page;
                    self.isBusy = false;
                    self.baseRarities = resp.data.rarities;
                    self.$forceUpdate();
                });
            }, 500);
        },
        addToCart(id) {
            this.isUpdating = true;
            let cart = {};
            if (this.$cookies.get("cart")) {
                cart = this.$cookies.get("cart");
            }

            let qty = this.quantity[id];
            if (!qty || isNaN(qty)) qty = 1;

            if (cart["c" + id]) {
                cart["c" + id] += parseInt(qty);
            } else {
                cart["c" + id] = parseInt(qty);
            }

            this.$gtag.event("add_to_cart", {
                event_category: "ecommerce",
                event_label: "add_to_cart",
                value: id
            });

            this.$cookies.set("cart", JSON.stringify(cart), 60 * 60 * 12);
            ApiService.post(`/cart/check`, { cart: cart }).then(() => {
                ApiService.post(`/cart/update`, { cart: cart }).then(resp => {
                    this.cart = resp.data;
                    let cHelp = {};
                    for (const it in this.cart) {
                        cHelp["c" + this.cart[it].sell_id] = this.cart[
                            it
                        ].quantity;
                    }

                    this.$cookies.set(
                        "cart",
                        JSON.stringify(cHelp),
                        60 * 60 * 12
                    );
                    this.isUpdating = false;
                    this.$root.$emit("updateCart", true);
                });
            });
        },
        encodeQueryData(data) {
            const ret = [];
            for (const d in data) {
                if (
                    data[d] &&
                    data[d] !== null &&
                    data[d] !== undefined &&
                    data[d] !== ""
                ) {
                    ret.push(
                        encodeURIComponent(d) +
                            "=" +
                            encodeURIComponent(data[d])
                    );
                }
            }
            return ret.join("&");
        }
    },
    computed: {
        indexUrl: function() {
            if (this.$store.state.auth.isAuthenticated) {
                return "card/v2";
            }

            return "card";
        }
    },
    data() {
        return {
            page: 1,
            quantity: {},
            numberOfPage: null,
            items: [],
            format: "moxfield",
            filterP: {
                s: null,
                paginate: 4
            }
        };
    }
};
</script>

<style scoped></style>
